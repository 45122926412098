import { Route, Redirect } from 'react-router-dom'
import React, { Suspense, lazy, Fragment } from 'react'
import Loader from 'react-loaders'
// import { getUser } from '../../../config/require_auth';
import { ToastContainer } from 'react-toastify'
const VacancyMaker = lazy(() =>
  import('../../AuthenticatedApp/VacancyMaker/index')
)
const Dashboards = lazy(() => import('../../AuthenticatedApp/Dashboards'))
const Vacancies = lazy(() => import('../../AuthenticatedApp/Vacancies'))
const Pages = lazy(() => import('../../AuthenticatedApp/Pages'))
const Leads = lazy(() => import('../../AuthenticatedApp/Leads'))
const SettingsSection = lazy(() =>
  import('../../AuthenticatedApp/SettingsSection')
)
const FormBuilder = lazy(() => import('../../AuthenticatedApp/FormBuilder'))
const Company = lazy(() => import('../../AuthenticatedApp/CompanySetup'))

const AppMain = () => {
  try {
    const data = JSON.parse(localStorage.appState)
    if (data.company === true) {
      return (
        <Fragment>
          {/* Dashboards */}
          <Suspense
            fallback={
              <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader type="ball-clip-rotate-multiple" />
                  </div>
                  <h6 className="mt-3">
                    Hold up
                    <small>We're loading you in</small>
                  </h6>
                </div>
              </div>
            }
          >
            <Route path="/dashboards/" component={Dashboards} />
          </Suspense>

          <Suspense
            fallback={
              <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader type="ball-clip-rotate-multiple" />
                  </div>
                  <h6 className="mt-3">
                    Hold up
                    <small>We're loading you in</small>
                  </h6>
                </div>
              </div>
            }
          >
            <Route path="/wizard/" component={VacancyMaker} />
          </Suspense>

          {/* Form Builder */}
          <Suspense
            fallback={
              <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader type="ball-clip-rotate-multiple" />
                  </div>
                  <h6 className="mt-3">
                    Hold up
                    <small>We're loading you in</small>
                  </h6>
                </div>
              </div>
            }
          >
            <Route path="/form/" component={FormBuilder} />
          </Suspense>
          <Suspense
            fallback={
              <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader type="ball-clip-rotate-multiple" />
                  </div>
                  <h6 className="mt-3">
                    Hold up
                    <small>We're loading you in</small>
                  </h6>
                </div>
              </div>
            }
          >
            <Route path="/vacancy/" component={Vacancies} />
          </Suspense>
          <Suspense
            fallback={
              <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader type="ball-clip-rotate-multiple" />
                  </div>
                  <h6 className="mt-3">
                    Hold up
                    <small>We're loading you in</small>
                  </h6>
                </div>
              </div>
            }
          >
            <Route path="/page/" component={Pages} />
          </Suspense>
          <Suspense
            fallback={
              <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader type="ball-clip-rotate-multiple" />
                  </div>
                  <h6 className="mt-3">
                    Hold up
                    <small>We're loading you in</small>
                  </h6>
                </div>
              </div>
            }
          >
            <Route path="/leads/" component={Leads} />
          </Suspense>
          <Suspense
            fallback={
              <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader type="ball-clip-rotate-multiple" />
                  </div>
                  <h6 className="mt-3">
                    Hold up
                    <small>We're loading you in</small>
                  </h6>
                </div>
              </div>
            }
          >
            <Route path="/settings/" component={SettingsSection} />
          </Suspense>
          <Route
            exact
            path="/"
            render={() => <Redirect to="/dashboards/home" />}
          />
          <Suspense
            fallback={
              <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader type="line-scale-party" />
                  </div>
                  <h6 className="mt-3">
                    Please wait while we load all the Pages examples
                    <small>
                      Because this is a demonstration we load at once all the
                      Pages examples. This wouldn't happen in a real live app!
                    </small>
                  </h6>
                </div>
              </div>
            }
          >
            {/* <Route path="/pages" component={UserPages}/> */}
          </Suspense>
          <Suspense
            fallback={
              <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader type="ball-clip-rotate-multiple" />
                  </div>
                  <h6 className="mt-3">
                    Hold up
                    <small>We're loading you in</small>
                  </h6>
                </div>
              </div>
            }
          >
            <Route path="dashboards/vacancy" component={Dashboards} />
          </Suspense>
          <Suspense
            fallback={
              <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader type="ball-clip-rotate-multiple" />
                  </div>
                  <h6 className="mt-3">
                    Hold up
                    <small>We're loading you in</small>
                  </h6>
                </div>
              </div>
            }
          ></Suspense>
          <ToastContainer />
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <Suspense
            fallback={
              <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader type="ball-clip-rotate-multiple" />
                  </div>
                  <h6 className="mt-3">
                    Hold up
                    <small>We're loading you in</small>
                  </h6>
                </div>
              </div>
            }
          >
            <Route path="*" component={Company} />
          </Suspense>
          <ToastContainer />
        </Fragment>
      )
    }
  } catch (e) {
    return false
  }
}

export default AppMain
