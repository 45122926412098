import { Route, HashRouter, Switch } from 'react-router-dom'
import React, { Suspense, lazy } from 'react'
import Loader from 'react-loaders'

const Login = lazy(() => import('./Login'))
const Register = lazy(() => import('./Register'))
const ForgotPassword = lazy(() => import('./ForgotPassword'))
const ResetPassword = lazy(() => import('./ResetPassword'))

const UnauthenticatedApp = () => {
  return (
    <Suspense
      fallback={
        <div className="loader-container">
          <div className="loader-container-inner">
            <div className="text-center">
              <Loader type="ball-clip-rotate-multiple" />
            </div>
            <h6 className="mt-3">
              Hold up
              <small>We're loading you in</small>
            </h6>
          </div>
        </div>
      }
    >
      <HashRouter>
        <Switch>
          <Route path="/register" component={Register} />
          <Route path="/password-recovery" component={ForgotPassword} />
          <Route path="/password-reset" component={ResetPassword} />
          <Route path="*" component={Login} />
        </Switch>
      </HashRouter>
    </Suspense>
  )
}
export default UnauthenticatedApp
