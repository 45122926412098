import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { HashRouter, Switch } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import './assets/base.scss'
import App from './resources'
import 'autotrack/lib/plugins/url-change-tracker'
import configureStore from './config/configureStore'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faStroopwafel, faCalendarAlt } from '@fortawesome/pro-solid-svg-icons'
import {
  faUserPlus,
  faAddressCard,
  faPhoneAlt,
  faComments,
  faListUl,
  faTrashAlt,
  faFileAlt,
  faMapMarkerCheck,
  faHandshake,
  faUserSlash,
  faPhoneSlash,
  faTimesCircle,
} from '@fortawesome/pro-light-svg-icons'
import { faSparkles } from '@fortawesome/pro-regular-svg-icons'
library.add(
  faStroopwafel,
  faTrashAlt,
  faCalendarAlt,
  faSparkles,
  faUserPlus,
  faAddressCard,
  faPhoneAlt,
  faComments,
  faListUl,
  faFileAlt,
  faMapMarkerCheck,
  faHandshake,
  faUserSlash,
  faPhoneSlash,
  faTimesCircle
)
const store = configureStore()
const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

function AppRoot() {
  return (
    <Provider store={store}>
      <HashRouter>
        <Switch>
          <App />
        </Switch>
      </HashRouter>
    </Provider>
  )
}

root.render(<AppRoot />)

// if (module.hot) {
//   module.hot.accept('./resources', () => {
//     const NextApp = require('./resources')
//     renderApp(NextApp)
//   })
// }
serviceWorker.unregister()
