// If user not authenticated render out to root
function getUser() {
  const appstate = localStorage.getItem('appState')
  if (helpers.jsonParser(appstate)) {
    const appstateParsed = JSON.parse(appstate)
    const time = new Date()
    if (!appstateParsed.user.remember_token) {
      return false
    }
    if (time > appstateParsed.user.expires_at) {
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}
const helpers = {
  jsonParser: function(str) {
    try {
      return JSON.parse(str)
    } catch (e) {
      return false
    }
  },
}

export { getUser }
