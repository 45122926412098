import React from 'react'
import { getUser } from '../config/require_auth'
import AuthenticatedApp from './AuthenticatedApp/Main'
import UnauthenticatedApp from './UnauthenticatedApp/index'
function App() {
  const user = getUser()

  return user ? <AuthenticatedApp /> : <UnauthenticatedApp />
}
export default App
